// https://zenn.dev/hamo/articles/725e4189bfc54d
// https://zenn.dev/aldagram_tech/articles/c2cf248bd016fc

import React from 'react';
import {useDroppable} from '@dnd-kit/core';


export const Board = (props) => {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id
  });
  const style = {
    color: isOver ? 'green' : undefined,
    display: 'inline-block',
    _display: 'inline'
  };

  return (
    <div ref={setNodeRef} style={style}>
     {props.children}
    </div>
  );
}

