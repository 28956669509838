import { BrowserRouter, HashRouter, Link, Routes, Route, Navigate } from "react-router-dom";
import Top from "./Top";
import Bookmark from "./Bookmark";
import Sharefolder from "./Sharefolder";
import PrivateRoute from './PrivateRoute';
import { useAuth } from './use-auth';
import { SignIn } from './SignIn';
import { ChangePassword } from './ChangePassword';
import { ChangeEmail } from './ChangeEmail';
import { Helmet, HelmetProvider } from "react-helmet-async";

const Routermenu = () => {

  const auth = useAuth();

  if (auth.isLoading) {
    return <div></div>;
  }

  const TopPage = () => (
    <div>
      <p>トップページ</p>
      {auth.isAuthenticated ? <Navigate to="/privatetop" /> : <Navigate to="/signin" />}
      <p>
        <Link to="/signin">ログイン</Link>
      </p>
    </div>
  );

  const PrivateTop = () => (
    <PrivateRoute>
      <div>ようこそ！ {auth.email}{/* (ID : {auth.sub} ) */} さん！</div>
      <p><Link to="/bookmark">ブックマーク</Link></p>
      <p><Link to="/sharefolder">共有フォルダ</Link></p>
      <p>
       <form method="POST" name="redirect_form" action="https://sys01.o-yasumi.com/kakei/login_from_portal.php">
        <input type="hidden" name="idtoken" value={auth.jwtToken} />
        <button type="submit">家計簿システム</button>
       </form>
      </p>
      <p><Link to="/changepassword">パスワードを変更する</Link></p>
      <p><Link to="/changeemail">メールアドレスを変更する</Link></p>
      <button onClick={() => auth.signOut()}>ログアウト</button>
    </PrivateRoute>
  );

  return (
   <HelmetProvider>
    <Helmet>
     <title>ポータル</title>
    </Helmet>
    <HashRouter>
      <Routes>
        <Route index element={<TopPage />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="privatetop" element={<PrivateTop />}></Route>
        <Route path="changepassword" element={<ChangePassword />}></Route>
        <Route path="changeemail" element={<ChangeEmail />}></Route>
        <Route path="bookmark" element={<Bookmark />} />
        <Route path="sharefolder" element={<Sharefolder />} />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </HashRouter>
   </HelmetProvider>
  );
};

export default Routermenu;




