import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routermenu from './Routermenu';
// import ListApp from './ListApp';
// import RouterApp from './RouterApp';
// import Todo from './todo';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from './use-auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
   <ProvideAuth>
    <Routermenu />
   </ProvideAuth>
  </React.StrictMode>
);
// root.render(
//   <React.StrictMode>
//     <ListApp />
//   </React.StrictMode>
// );
// root.render(
//   <React.StrictMode>
//     <RouterApp />
//   </React.StrictMode>
// );
// root.render(
//   <React.StrictMode>
//     <Todo />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
