import React from 'react';
import {useState,useRef,useEffect} from 'react';

import { BookmarkItem } from "./BookmarkItem";
import './Bookmark.css';

import {AttrDialog} from './AttrDialog';

export const BookmarkRecursive = ({ list, rootid, dndparent, childclick }) => {

  const [isdiropen, setisdiropen] = useState(false);
  const [isurlopen, setisurlopen] = useState(false);
  const [selectrootid, setselectrootid] = useState(0);
  const [treevisible, settreevisible] = useState([]);

  const make_dir = (e: ChangeEvent<HTMLInputElement>) => {
   const param = { method : "POST", type : "dir", name : "hogehogedir", parentid : e.target.getAttribute("rootid") };
   childclick(JSON.stringify(param));
   // childclick("フォルダを追加します。 rootid: " + e.target.getAttribute("rootid"));
  }
  const make_url = (e: ChangeEvent<HTMLInputElement>) => {
   const param = { method : "POST", type : "url", name : "hogehogeurl", url : "http://www.hogehoge.com", parentid : e.target.getAttribute("rootid") };
   childclick(JSON.stringify(param));
   // childclick("サイトを追加します。 rootid: " + e.target.getAttribute("rootid"));
  }

  const style = {
    display: 'inline-block',
    _display: 'inline'
  };

  const openDialog = (e: ChangeEvent<HTMLInputElement>) => {
   setselectrootid(e.target.getAttribute("rootid"));
   if( e.target.getAttribute("type") == "dir" ) {
    setisdiropen(true);
   }
   else if( e.target.getAttribute("type") == "url" ) {
    setisurlopen(true);
   }
  }

  const closeDialog = ( param: any) => {
   setisdiropen(false);
   setisurlopen(false);
   if( param ) {
    childclick(JSON.stringify(param));
   }
   // else {
   //  alert("ダイアログが閉じられました。 param : " + JSON.stringify(param));
   // }
  }

  const treechange = (e: ChangeEvent<HTMLInputElement>) => {
   // treevisible.forEach((each) => {
   //  console.log(each);
   // });
   // alert("配下の表示を切り替えます。 ID: " + e.currentTarget.attributes['id'].value);
   // treevisible に 選択したid( e.currentTarget.attributes['id'].value )が入っているかどうかを確認し、入っていたら抜き、入っていなかったら入れる
   if( treevisible.includes(e.currentTarget.attributes['id'].value) ) {
    // alert(e.currentTarget.attributes['id'].value + " を削除します。");
    settreevisible(
        treevisible.filter((each, index) => (each !== e.currentTarget.attributes['id'].value))
    )
   }
   else {
    // alert(e.currentTarget.attributes['id'].value + " を追加します。");
    settreevisible([...treevisible, e.currentTarget.attributes['id'].value])
   }
  }

  // numindir でソート
  const targetList = list.filter(item => Number(item.parentid.N) === rootid)
   .sort( (a,b) => {
    if(Number(a.numindir.N) < Number(b.numindir.N)) return -1;
    if(Number(a.numindir.N) > Number(b.numindir.N)) return 1;
    return 0;
   });

  return (
   <div>
    <AttrDialog isOpen={isdiropen} onClose={closeDialog} method='POST' type='dir' rootid={selectrootid} />
    <AttrDialog isOpen={isurlopen} onClose={closeDialog} method='POST' type='url' rootid={selectrootid} />
    {(rootid==0) && (
     <div style={style}>
      <button onClick={openDialog} type="dir" rootid="0" >フォルダ追加</button>
      <button onClick={openDialog} type="url" rootid="0" >サイト追加</button>
     </div>
    )}
    <ul>
      {targetList.map(item => (
        <li key={Number(item.id.N)}>
          {(item.type.S=='dir') && (
           <div style={style} tabIndex={1} id={Number(item.id.N)} onClick={(e)=>{treechange(e)}} onKeyPress={(e) => { if(e.key==="Enter")treechange(e); }} role="button">
           {(treevisible.includes(item.id.N)) ? (
            <img src="symbol023.png" width="20" height="20" />
           ) : (
            <img src="symbol024.png" width="20" height="20" />
           )}
           </div>
          )}
          <BookmarkItem key={Number(item.id.N)} id={Number(item.id.N)} type={item.type.S} url={item.url.S} name={item.name.S} parentid={rootid} numindir={Number(item.numindir.N)} childclick={childclick} />
          {(item.type.S=='dir') && (
           <div style={style}>
            <button onClick={openDialog} type="dir" rootid={Number(item.id.N)} >配下にフォルダ追加</button>
            <button onClick={openDialog} type="url" rootid={Number(item.id.N)} >配下にサイト追加</button>
           </div>
          )}
          
          {(treevisible.includes(item.id.N)) && ( <div>
           {list.find(l => Number(l.parentid.N) === Number(item.id.N)) &&
             <BookmarkRecursive list={list} rootid={Number(item.id.N)} dndparent={dndparent} childclick={childclick} />
           }
          </div>)}
        </li>
      ))}
    </ul>
   </div>
  )
}




