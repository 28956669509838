// https://mseeeen.msen.jp/react-auth-with-ready-made-cognito/
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './use-auth';

export function ChangePassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [cur_password, setCurPassword] = useState('');
  const [new_password_1, setNewPassword1] = useState('');
  const [new_password_2, setNewPassword2] = useState('');

  const executeChangePassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if( new_password_1 != new_password_2 ) {
     alert("2箇所の新しいパスワードが合っていません。"); 
     return false;
    }
    if( auth.jwtToken ) {
     alert("email : " + auth.email + " のパスワードを変更します。");
     const result = await auth.changePassword(cur_password, new_password_1);
     if (result.success) {
      alert(result.message);
      navigate({ pathname: '/PrivateTop' });
     }
     else {
      alert(result.message);
     }
    }
    else {
     alert("email : " + auth.email + " のパスワードを初期値から変更します。");
     const result = await auth.activatePassword(auth.email, cur_password, new_password_1);
     if (result.success) {
      alert(result.message);
      navigate({ pathname: '/PrivateTop' });
     }
     else {
      alert(result.message);
     }
    }
  };

  // https://reffect.co.jp/react/react-typescript-event/
  const local_signout = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
   await auth.signOut();
   navigate({ pathname: '/' });
  }


  return (
   <div>
    { !auth.jwtToken && (<p>パスワードの変更が必要です。</p>) }
    <form noValidate onSubmit={executeChangePassword}>
      <div>
        <label htmlFor="password">現在のパスワード: </label>
        <input
          id="cur_password"
          type="password"
          value={cur_password}
          onChange={(e) => setCurPassword(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">新しいパスワード: </label>
        <input
          id="new_password_1"
          type="password"
          value={new_password_1}
          onChange={(e) => setNewPassword1(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">新しいパスワード(確認): </label>
        <input
          id="new_password_2"
          type="password"
          value={new_password_2}
          onChange={(e) => setNewPassword2(e.target.value)}
        />
      </div>
      <button type="submit">変更</button>
    </form>
    { auth.jwtToken ? (<Link to={`/`}>ホームに戻る</Link>) : (<button onClick={local_signout}>ログアウト</button>) }
   </div>
  );
}

