import * as React from 'react';
import ReactModal from 'react-modal';

interface Props {
  /** このダイアログを表示するなら true */
  isOpen: boolean;
  method: string;
  type: string;
  rootid: number;
  modid : number;
  cur_name : string;
  cur_url : string;
  /** このダイアログを閉じるときのコールバック */
  onClose?: (param: any) => void;
}

interface State {
  name: string;
  url: string;
  message: string;
}

interface HTMLButtonEvent extends Event {
  target: HTMLButtonElement;
}

export class AttrDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      url : '',
      message : ''
    };

    // 具体的に #root 要素などを指定した方がよい？
    ReactModal.setAppElement('body');
  }

  public render(): React.ReactNode {

    const messtyle = {
     color: 'red',
     display: 'inline-block',
     _display: 'inline'
    };

    return <div>
      <ReactModal
        isOpen={this.props.isOpen}
        onAfterOpen={this.handleOpen}
        onRequestClose={this.handleClose}
        style={this.customStyles}
        contentLabel="追加/変更"
      >
       <p>{ this.props.method=="POST" ? <span>追加</span> : <span>変更</span> }する{ this.props.type=="dir" ? <span>フォルダ名</span> : <span>サイト名・URL</span> }を入力してください</p>
        <div style={messtyle}><p>{this.state.message}</p></div>
        <p><label>名前<input type="text" autoFocus value={this.state.name} onChange={this.handleChangename}></input></label></p>
        { this.props.type=="url" && <p><label>URL<input type="text" value={this.state.url} onChange={this.handleChangeurl}></input></label></p> }
        <button className="exec" onClick={ () => this.handleClose({ method : this.props.method, type : this.props.type, name : this.state.name, url : this.state.url, parentid : this.props.rootid, id : this.props.modid }) } >実行</button>
        <button className="exec" onClick={ () => this.handleClose(null)} >キャンセル</button>

      </ReactModal>
    </div>;
  }


  // フォームのサブミット時にダイアログを閉じる
  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.handleClose(null);
  }

  private handleChangename = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({name: event.target.value})
  }

  private handleChangeurl = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({url: event.target.value})
  }

  // ダイアログが開いたときに呼び出される
  private handleOpen = () => {
    // ここで設定情報などを読み込む
    if( this.props.method == "PUT" ) {
     this.setState({name: this.props.cur_name});
     if( this.props.type == "url" ) {
      this.setState({url: this.props.cur_url});
     }
    }
  }

  // 実行、キャンセル、ダイアログ領域外のクリックや、ESCキーを押したときに呼び出される
  private handleClose = (param: any) => {
    if( param ) {
     if( this.props.type=="dir" && !this.state.name ) {
      this.setState({message: "名前が入っていません"});
      return;
     }
     if( this.props.type=="url" ) {
      const pattern = /^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/;
      if( ! pattern.test(this.state.url) ) {
       this.setState({message: "URLが正しくありません"});
       return;
      }
     }
    }
    this.setState({message: ''});
    this.setState({name: ''});
    if( this.props.type == "url" ) {
      this.setState({url: ''});
    }
    // 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
    this.props.onClose?.(param);
  }

  // スタイルのカスタマイズ
  private customStyles: ReactModal.Styles = {
    // ダイアログ内のスタイル（中央に表示）
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    // 親ウィンドウのスタイル（ちょっと暗くする）
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  }
}

