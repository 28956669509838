// https://mseeeen.msen.jp/react-auth-with-ready-made-cognito/
import { Navigate } from 'react-router-dom';
import { useAuth } from './use-auth';

// https://www.sharooq.com/solved-type-children-element-has-no-properties-in-common-with-type-intrinsicattributes-react
// https://zenn.dev/json_hardcoder/articles/4c730bcd4344bf
// https://scrapbox.io/cybernote/IntrinsicAttributes%E3%81%AE%E3%82%A8%E3%83%A9%E3%83%BC
// const PrivateRoute: React.FC = ({ children }) => {
const PrivateRoute = (props: any) => {
  const { isAuthenticated } = useAuth();
  console.log("PrivateRoute props : " + JSON.stringify(props));
//  return isAuthenticated ? <>{children}</> : <Navigate to="/signin" />;
  return isAuthenticated ? <>{props.children}</> : <Navigate to="/signin" />;
};

export default PrivateRoute;

