// https://mseeeen.msen.jp/react-auth-with-ready-made-cognito/
const Auth = {
  region: process.env.REACT_APP_COGNITO_REGION,
  identityPoolId: process.env.REACT_APP_COGNITO_ID_POOL_ID,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
//  cookieStorage: {
//    domain: process.env.REACT_APP_COGNITO_COOKIE_STORAGE_DOMAIN,
//    path: '/',
//    expires: 365,
//    sameSite: "strict",
//    secure: true
//  },
  authenticationFlowType: 'USER_SRP_AUTH',
}

export default Auth;

