// https://mseeeen.msen.jp/react-auth-with-ready-made-cognito/
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './use-auth';

export function ChangeEmail() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [new_email_1, setNewEmail1] = useState('');
  const [new_email_2, setNewEmail2] = useState('');
  // https://nishinatoshiharu.com/react-control-display/
  const [visible, setVisible] = useState(false);
  const [verifycode, setVerifycode] = useState('');

  const executeChangeEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if( new_email_1 != new_email_2 ) {
     alert("2箇所の新しいメールアドレスが合っていません。"); 
     return false;
    }
    alert("email : " + auth.email + " のメールアドレスを変更します。");
    const result = await auth.changeEmail(new_email_1);
    if (result.success) {
     alert(result.message);
     setVisible(true);
    }
    else {
     alert(result.message);
    }
  };

  const verifyEmail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    alert("認証コード : " + verifycode + " で検証します");
    const result = await auth.verifyEmail(verifycode);
    if (result.success) {
     alert(result.message);
    }
    else {
     alert(result.message);
    }
  }

  return (
   <div>
    <form noValidate onSubmit={executeChangeEmail}>
      <div>
        <label htmlFor="email">新しいメールアドレス: </label>
        <input
          id="new_email_1"
          type="email"
          value={new_email_1}
          onChange={(e) => setNewEmail1(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="email">新しいメールアドレス(確認): </label>
        <input
          id="new_email_2"
          type="email"
          value={new_email_2}
          onChange={(e) => setNewEmail2(e.target.value)}
        />
      </div>
      { !visible && (<button type="submit">変更</button>) }
    </form>
    { visible &&
      (
        <div>
         <p>新しいメールアドレスに送られてきた認証コードを入力し、送信してください。</p>       
         <input id="verifycode" type="number" value={verifycode} onChange={(e) => setVerifycode(e.target.value)} />
         <button onClick={verifyEmail}>認証コードを送信</button>
        </div>
      )
    }
    <Link to={`/`}>ホームに戻る</Link>
   </div>
  );
}

