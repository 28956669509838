import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import AwsConfigAuth from './auth';

Amplify.configure({ Auth: AwsConfigAuth });
// Auth.configure({ Auth: AwsConfigAuth });

Amplify.Logger.LOG_LEVEL = "DEBUG";

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  sub: string;
  email: string;
  jwtToken: string;
  signIn: (email: string, password: string) => Promise<Result>;
  changePassword: (cur_password: string, new_password: string) => Promise<Result>;
  activatePassword: (email: string, cur_password: string, new_password: string) => Promise<Result>;
  changeEmail: (new_email: string) => Promise<Result>;
  verifyEmail: (verifycode: string) => Promise<Result>;
  signOut: () => void;
  refresh_token: () => Promise<any>;
}

interface Result {
  success: boolean;
  challenge: boolean; // 仮パスワードの場合(パスワード変更が必要な場合) true (このとき success は false )
  message: string;
}

const authContext = createContext({} as UseAuth);

// export const ProvideAuth: React.FC = ({ children }) => {
export const ProvideAuth = (props: any) => {
  // Amplify.configure({ Auth: AwsConfigAuth });
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
//  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sub, setSub] = useState('');
  const [email, setEmail] = useState('');
//  const [password, setPassword] = useState('');
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    // console.log("start useEffect");
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setSub(result.signInUserSession.idToken.payload.sub);
        setEmail(result.signInUserSession.idToken.payload.email);
        setJwtToken(result.signInUserSession.idToken.jwtToken);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setSub('');
        setEmail('');
        setJwtToken('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signIn = async (input_email: string, input_password: string) => {
    try {
      const result = await Auth.signIn(input_email, input_password);
      // console.log("result : " + JSON.stringify(result));

      const hasChallenge = Object.prototype.hasOwnProperty.call(result, 'challengeName')
      if(hasChallenge) {
       setIsAuthenticated(true);
       setEmail(result.challengeParam.userAttributes.email);
       // console.log("challenge email : " + email);
       return {
        success: false,
        challenge: true,
        message: 'パスワードの変更が必要。result.challengeName : ' + result.challengeName + ' email : ' + email + " result.challengeParam.userAttributes.email : " + result.challengeParam.userAttributes.email
       };
      }

      setSub(result.signInUserSession.idToken.payload.sub);
      // console.log("sub : " + sub);
      setEmail(result.signInUserSession.idToken.payload.email);
      // console.log("email : " + email);
      setJwtToken(result.signInUserSession.idToken.jwtToken);
      setIsAuthenticated(true);
      return { success: true, challenge: false, message: '' };
    } catch (error) {
      return {
        success: false,
        challenge: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const changePassword = async (input_cur_password: string, input_new_password: string) => {
   try {
      const result=await Auth.currentAuthenticatedUser();
      await Auth.changePassword(result, input_cur_password, input_new_password);
      return { success: true, challenge: false, message: 'パスワードの変更に成功しました。' };
   } catch(error) {
      return {
        success: false,
        challenge: false,
        message: 'パスワードの変更に失敗しました。',
      };
   }
  };

  const activatePassword = async (input_email: string, input_cur_password: string, input_new_password: string) => {
    try {
      const result_1 = await Auth.signIn(input_email, input_cur_password);
      // console.log("result_1 : " + JSON.stringify(result_1));

      const result_2 = await Auth.completeNewPassword(result_1, input_new_password, {});

      setSub(result_2.signInUserSession.idToken.payload.sub);
      // console.log("sub : " + sub);
      setEmail(result_2.signInUserSession.idToken.payload.email);
      // console.log("email : " + email);
      setJwtToken(result_2.signInUserSession.idToken.jwtToken);
      setIsAuthenticated(true);
      return { success: true, challenge: false, message: 'パスワードの変更に成功しました。' };
    } catch (error) {
      return {
        success: false,
        challenge: false,
        message: 'パスワードの変更に失敗しました。',
      };
    }
  };

  const changeEmail = async (input_new_email: string) => {
   try {
      const result=await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(result, { email : input_new_email } );
      return { success: true, challenge: false, message: '新しいメールアドレスに認証コードを送信しましたので確認してください。' };
      // この契機で新しいメールアドレス宛てに「Your verification code is 405633」というメールが届く
      // この値を引数として Auth.verifyCurrentUserAttributeSubmit を実行しないと変更されない(変更前のメールアドレスでのログインのみできる)
   } catch(error) {
      return {
        success: false,
        challenge: false,
        message: 'メールアドレスの変更に失敗しました。',
      };
   }
  };

  const verifyEmail = async (input_verifycode: string) => {
   try {
      await Auth.verifyCurrentUserAttributeSubmit('email', input_verifycode);
      return { success: true, challenge: false, message: 'メールアドレスの変更が完了しました。' };
   } catch(error) {
      return {
        success: false,
        challenge: false,
        message: '認証コードが異なります。',
      };
   }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setEmail('');
      setJwtToken('');
      setIsAuthenticated(false);
      return { success: true, challenge: false, message: '' };
    } catch (error) {
      return {
        success: false,
        challenge: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  const refresh_token = async () => {
    return Auth.currentSession().then((data) => {
     return data;
    }).catch((err) => {
     throw err;
    }); 
  };

  // console.log("return sub : " + sub + " email : " + email + " jwtToken : " + jwtToken + " isAuthenticated : " + isAuthenticated);
  return {
    isLoading,
    isAuthenticated,
    sub,
    email,
    jwtToken,
    signIn,
    changePassword,
    activatePassword,
    changeEmail,
    verifyEmail,
    signOut,
    refresh_token
  };
};

